import i18next from 'i18next';
import { PER_MONTH } from 'src/js/modules/common/constants/terms';

const getAvailableDiscountCaptions = (term) => ({
  title: {
    gift: i18next.t('A gift from Yola:'),
    discount:
      term === PER_MONTH
        ? i18next.t('50% off for the next 2 months')
        : i18next.t('20% off for next renewal'),
  },
  description:
    term === PER_MONTH
      ? i18next.t('Stay with us and keep the benefits of your subscription at half the price')
      : i18next.t('Save big and keep all the benefits of your current plan'),
  discountItem: {
    price: i18next.t('Your current price'),
    discountPrice: i18next.t('Your NEW price'),
    term: term === PER_MONTH ? i18next.t('month') : i18next.t('year'),
  },
  buttonLabels: {
    activateDiscount: i18next.t('Activate discount'),
    next: i18next.t('Cancel anyway'),
  },
});

export default getAvailableDiscountCaptions;
