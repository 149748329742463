import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import i18next from 'i18next';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import get from 'lodash.get';
import products from 'src/js/modules/products';
import dialogs from 'src/js/modules/dialogs';
import requestStatus from 'src/js/modules/status';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import routesMap from '../../../router/helpers/routes-map';
import UpsellBannersContainer from '../../upsell-banners/containers/upsell-banners-container';
import { PageHeading, PageHeadingLeft } from '../../../common/components/page-container';
import supportedTabs from '../constants/supported-tabs';
import SubscriptionDetailsContainer from '../containers/subscription-details-container';
import segment from '../../analytics/segment';
import useQuery from '../../../common/hooks/use-query';

const { Heading, ActionButton, Stack } = designSystem;
const {
  constants: { events, triggerIds },
  track,
} = segment;

const SubscriptionDetailsPage = ({ subscription }) => {
  const { id: subId, type, term, status, autoRenew } = subscription;
  const location = useLocation();
  // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  const sourceId = useMemo(() => location?.state?.sourceId || null, [location.pathname]);
  const isMatchCancelSubscription = Boolean(useRouteMatch(routesMap.cancelSubscription.patterns));
  const dispatch = useDispatch();

  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const ecommercePackages = useSelector(selectors.getEcommercePlans, shallowEqual);
  const topAvailableHostingPackage = useSelector(
    selectors.getTopAvailableHostingPackage,
    shallowEqual
  );
  const topAvailableEcommercePlan = useSelector(
    selectors.getTopAvailableEcommercePlan,
    shallowEqual
  );
  const activateDiscountStatus = useSelector(products.selectors.getCreateCancellationCouponStatus);

  // we need to load `difm_latitude` flag to prevent loading in the Cancel dialog
  // in case when cancellation flow is opened by link
  const [featureFlags] = useFeatureFlags(['cancel_button', 'cancellation_flow']);
  const { data: coupon, isLoading: isCouponLoading } = useQuery({
    name: products.statusNames.CANCELLATION_COUPON,
    thunk: products.thunks.fetchPackageCancellationCoupon,
  });

  const { cancel_button: isCancelButtonEnabled, cancellation_flow: isCancellationFlowEnabled } =
    featureFlags;
  const isJustDiscountActivated = activateDiscountStatus === requestStatus.constants.SUCCEEDED;
  const hasEverHadDiscount = Boolean(Object.keys(coupon).length) || isJustDiscountActivated;
  const hasActivatedDiscount =
    (get(coupon, 'subscriptionTerm') === term && Boolean(get(coupon, 'volume', false))) ||
    isJustDiscountActivated;
  const isCouponLoaded = Boolean(coupon);
  const isHostingSubscription = verifiers.isHostingPackageType(type);
  const targetPackage = isHostingSubscription ? availablePackages[type] : ecommercePackages[type];

  const topAvailablePackage = isHostingSubscription
    ? topAvailableHostingPackage
    : topAvailableEcommercePlan;

  const handleBackClick = () => {
    dispatch(push(routesMap.accountManagement.url({ tabId: supportedTabs.SUBSCRIPTIONS })));
  };

  useEffect(() => {
    if (isMatchCancelSubscription && isCouponLoaded) {
      if (isCancellationFlowEnabled) {
        dispatch(
          dialogs.actions.show(dialogs.dialogTypes.CANCEL_PLAN, {
            subId,
            sourceId,
            hasEverHadDiscount,
            hasActivatedDiscount,
          })
        );
      } else {
        dispatch(replace(routesMap.subscriptionDetails.url({ subId })));
      }
    }
  }, [
    isMatchCancelSubscription,
    dispatch,
    subId,
    sourceId,
    hasActivatedDiscount,
    hasEverHadDiscount,
    isCouponLoaded,
    isCancellationFlowEnabled,
  ]);

  useEffect(() => {
    track(events.SUBSCRIPTION_DETAILS_PAGE_DISPLAYED, {
      type,
      term,
      status,
      autoRenew,
      category: isHostingSubscription ? triggerIds.HOSTING : triggerIds.ONLINE_STORE,
    });
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <PageHeading>
        <PageHeadingLeft>
          <Stack gap="spacing-2-xs">
            <div>
              <ActionButton
                format="text"
                iconGlyph="back"
                label={i18next.t('Return to Subscriptions')}
                onClick={handleBackClick}
              />
            </div>
            <Heading type="heading-2">
              {`${isHostingSubscription ? i18next.t('Hosting plan') : i18next.t('Online store')}: ${
                targetPackage.name
              }`}
            </Heading>
          </Stack>
        </PageHeadingLeft>
      </PageHeading>
      <div className="ws-account-management-content">
        <SubscriptionDetailsContainer
          subscriptionMeta={subscription}
          name={targetPackage.name}
          isHostingSubscription={isHostingSubscription}
          features={targetPackage.features}
          topAvailablePackageType={topAvailablePackage.type}
          hasActivatedDiscount={hasActivatedDiscount}
          hasEverHadDiscount={hasEverHadDiscount}
          isCancelButtonEnabled={isCancelButtonEnabled}
          isLoading={isCouponLoading || !isCouponLoaded}
          isJustDiscountActivated={isJustDiscountActivated}
        />
        <UpsellBannersContainer />
      </div>
    </Fragment>
  );
};

SubscriptionDetailsPage.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    autoRenew: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SubscriptionDetailsPage;
