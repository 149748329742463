import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { fieldNames, SUB_FIELD_OTHER_VALUE } from './constants/common';
import getHostingPrimaryReasonFields from './helpers/get-hosting-primary-reason-fields';
import getEcommercePrimaryReasonFields from './helpers/get-ecommerce-primary-reason-fields';
import { CANCEL_PLAN_TERMS_OF_USE_PROP_TYPES } from '../cancel-plan-terms-of-use/constants';
import CancelPlanTermsOfUse from '../cancel-plan-terms-of-use';

const { Heading, Paragraph, Stack, ControlGroup } = designSystem;

const CancelPlanExitSurvey = ({
  expiryDate,
  termsOfUseUrl,
  isHostingSubscription,
  isClickToCancelComplianceEnabled,
  captions,
  control,
  watchForm,
  setFieldValue,
}) => {
  const values = watchForm();
  const {
    [fieldNames.PRIMARY_REASON]: primaryReason,
    [fieldNames.OTHER_PRIMARY_REASON]: otherPrimaryReason,
    [fieldNames.SUB_REASONS]: subReasons,
    [fieldNames.OTHER_SUB_REASON]: otherSubReason,
  } = values;
  const isSurveyRequired = !isClickToCancelComplianceEnabled;

  const fields = useMemo(
    () => ({
      [fieldNames.PRIMARY_REASON]: isHostingSubscription
        ? getHostingPrimaryReasonFields({ setFieldValue, required: isSurveyRequired })
        : getEcommercePrimaryReasonFields({ setFieldValue, required: isSurveyRequired }),
    }),
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
    [isHostingSubscription]
  );

  const renderFields = useCallback((field, fieldValues) => {
    const extractFieldToList = (fieldProps = {}, list = []) => {
      const fieldValue = fieldValues[fieldProps.name];
      const value = Array.isArray(fieldValue)
        ? fieldValue.find((val) => val === SUB_FIELD_OTHER_VALUE)
        : fieldValue;
      const subField = value && fieldProps.subFields && fieldProps.subFields[value];

      const newList = [...list, fieldProps];

      if (subField) {
        // Recursively call function to get sub field
        return extractFieldToList(subField, newList);
      }

      return newList;
    };

    const fieldList = extractFieldToList(field);

    return (
      <Stack gap="spacing-xs">
        {fieldList.map(({ Component, controlGroup, ...fieldProps }) =>
          controlGroup ? (
            <ControlGroup
              key={fieldProps.name}
              title={controlGroup.title}
              required={controlGroup.required}
            >
              <Component control={control} {...fieldProps} />
            </ControlGroup>
          ) : (
            <Component key={fieldProps.name} control={control} {...fieldProps} />
          )
        )}
      </Stack>
    );
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  const primaryReasonFields = useMemo(
    () =>
      renderFields(fields[fieldNames.PRIMARY_REASON], {
        primaryReason,
        otherPrimaryReason,
        subReasons,
        otherSubReason,
      }),
    [primaryReason, otherPrimaryReason, subReasons, otherSubReason, fields, renderFields]
  );

  return (
    <Stack gap="spacing-m">
      <Stack>
        <Heading type="heading-5">{captions.title}</Heading>
        <Paragraph>{captions.description}</Paragraph>
      </Stack>
      {primaryReasonFields}
      <CancelPlanTermsOfUse expiryDate={expiryDate} termsOfUseUrl={termsOfUseUrl} />
    </Stack>
  );
};

CancelPlanExitSurvey.propTypes = {
  ...CANCEL_PLAN_TERMS_OF_USE_PROP_TYPES,
  isHostingSubscription: PropTypes.bool.isRequired,
  isClickToCancelComplianceEnabled: PropTypes.bool.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  control: PropTypes.object.isRequired,
  watchForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CancelPlanExitSurvey;
