import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { selectors, verifiers } from '@yola/subscription-manager-js';
import { reactHookForm } from '@yola/ws-ui';
import dialogs from 'src/js/modules/dialogs';
import user from 'src/js/modules/user';
import { push } from 'connected-react-router';
import segment from 'src/js/modules/analytics/segment';
import subscriptions from 'src/js/modules/subscriptions';
import products from 'src/js/modules/products';
import routesMap from 'src/js/router/helpers/routes-map';
import status from 'src/js/modules/status';
import useLocaleDate from 'src/js/modules/common/hooks/use-locale-date';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import cancelPlanSteps from '../constants/cancel-plan-steps';
import { dialogIds } from '../constants/analytics';
import CancelPLanModal from '../components/cancel-plan-modal';
import CancelPlanConfirmation from '../components/cancel-plan-confirmation';
import CancelPlanExitSurvey from '../components/cancel-plan-exit-survey';
import CancelPlanDiscount from '../components/cancel-plan-discount';
import useCancelPlanSteps from '../hooks/use-cancel-plan-steps';
import getCancelPlans from '../helpers/get-cancel-plans';
import getCancelPlanDetails from '../helpers/get-cancel-plan-details';
import getCancelHostingPlanAdditionalChanges from '../helpers/get-cancel-hosting-plan-additional-changes';
import getCancelEcommercePlanAdditionalChanges from '../helpers/get-cancel-ecommerce-plan-additional-changes';
import getAvailableDiscountCaptions from '../helpers/get-available-discount-captions';
import { fieldNames } from '../components/cancel-plan-exit-survey/constants/common';
import useActiveUserSubscriptions from '../hooks/use-active-user-subscriptions';

const {
  constants: { events, triggerIds },
  track,
  trackers: { trackEvent },
} = segment;
const { useForm } = reactHookForm;

const getCaptions = () => ({
  [cancelPlanSteps.CONFIRMATION]: {
    youAreCancelling: i18next.t('You are cancelling:'),
    cancellationDetails: i18next.t('Сancellation details include:'),
    additionalChanges: i18next.t('Additional changes'),
  },
  [cancelPlanSteps.EXIT_SURVEY]: {
    title: i18next.t('Please share your feedback with us'),
    description: i18next.t('What could we do to improve?'),
  },
});

const initialFormData = {
  [fieldNames.PRIMARY_REASON]: '',
  [fieldNames.SUB_REASONS]: [],
  [fieldNames.OTHER_PRIMARY_REASON]: '',
  [fieldNames.OTHER_SUB_REASON]: '',
  [fieldNames.BETTER_ALTERNATIVE]: '',
};

const CancelPlanDialogContainer = ({
  subId,
  sourceId,
  hasEverHadDiscount,
  hasActivatedDiscount,
}) => {
  const subscription = useSelector(
    (state) => selectors.getActiveSubscriptionById(state, subId),
    shallowEqual
  );
  const availablePackages = useSelector(selectors.getHostingPackages, shallowEqual);
  const ecommercePackages = useSelector(selectors.getEcommercePlans, shallowEqual);
  const termsUrl = useSelector(user.selectors.getTermsUrl, shallowEqual);
  const cancelStatus = useSelector(subscriptions.selectors.getCancelSubscriptionStatus);
  const activateDiscountStatus = useSelector(products.selectors.getCreateCancellationCouponStatus);
  const fetchPricesStatus = useSelector(products.selectors.getPricesStatus);
  const isB2C = useSelector(user.selectors.getIsB2C);

  const { type, term, expiry_date: expiryDate, name } = subscription;
  const isHostingSubscription = verifiers.isHostingPackageType(type);
  const targetPackage = isHostingSubscription ? availablePackages[type] : ecommercePackages[type];
  const isCanceling = cancelStatus === status.constants.LOADING;
  const isError = cancelStatus === status.constants.FAILED;
  const isDiscountActivating = activateDiscountStatus === status.constants.LOADING;
  const isDiscountActivatingError = activateDiscountStatus === status.constants.FAILED;
  const isPricesFetching = fetchPricesStatus === status.constants.LOADING;

  const dispatch = useDispatch();
  const expiryDateFormatted = useLocaleDate(expiryDate);
  const userSubscriptions = useActiveUserSubscriptions();
  const [featureFlags] = useFeatureFlags(['click_to_cancel_compliance']);
  const availablePlatformComponents = user.hooks.useAvailablePlatformComponents();
  const { basePrice, discountPrice } = products.hooks.useFormattedSubscriptionPrice(type, term);

  const { click_to_cancel_compliance: isClickToCancelComplianceEnabled } = featureFlags;

  const { activeStep, isFirstStep, isLastStep, onNext, onBack } = useCancelPlanSteps({
    isHostingSubscription,
    hasEverHadDiscount,
  });

  const {
    control,
    getValues,
    formState: { isValid },
    watch: watchForm,
    setValue: setFieldValue,
  } = useForm({
    defaultValues: initialFormData,
    mode: 'onTouched',
  });

  const isDisabledNext =
    (activeStep === cancelPlanSteps.EXIT_SURVEY && !isValid) || isPricesFetching;
  const plansToCancel = useMemo(
    () => getCancelPlans(userSubscriptions, isHostingSubscription),
    [userSubscriptions, isHostingSubscription]
  );
  const onlineStoreIncluded = isHostingSubscription && plansToCancel.length > 1;
  const traits = useMemo(
    () => ({
      discountActivated: hasEverHadDiscount,
      discountExpired: hasEverHadDiscount && !hasActivatedDiscount,
      onlineStoreIncluded,
    }),
    [hasActivatedDiscount, hasEverHadDiscount, onlineStoreIncluded]
  );
  const analyticsParams = {
    dialogId: dialogIds.CANCEL_SUBSCRIPTION,
    stepId: activeStep,
    type,
    term,
    category: isHostingSubscription ? triggerIds.HOSTING : triggerIds.ONLINE_STORE,
    sourceId,
    ...traits,
  };

  const captions = useMemo(() => getCaptions(), []);
  const discountCaptions = useMemo(() => getAvailableDiscountCaptions(term), [term]);
  const cancelPlanDetails = useMemo(
    () => getCancelPlanDetails(expiryDateFormatted, isHostingSubscription),
    [expiryDateFormatted, isHostingSubscription]
  );
  const cancelPlanAdditionalChanges = useMemo(
    () =>
      isHostingSubscription
        ? getCancelHostingPlanAdditionalChanges({
            isB2C,
            availablePackages,
            userSubscriptions,
            availablePlatformComponents,
          })
        : getCancelEcommercePlanAdditionalChanges({ isB2C, ecommercePackages, name }),
    [
      isB2C,
      availablePackages,
      userSubscriptions,
      availablePlatformComponents,
      isHostingSubscription,
      ecommercePackages,
      name,
    ]
  );

  const clearError = () => {
    dispatch(status.actions.resetStatus(subscriptions.statusNames.CANCEL_SUBSCRIPTION));
  };

  const clearDiscountActivationError = () => {
    dispatch(status.actions.resetStatus(products.statusNames.CREATE_CANCELLATION_COUPON));
  };

  const closeDialog = ({ isTrackAnalytics = true, triggerId = null } = {}) => {
    if (isTrackAnalytics) {
      track(events.CANCEL_PLAN_DIALOG_CLOSED, { ...analyticsParams, triggerId });
    }
    dispatch(push(routesMap.subscriptionDetails.url({ subId })));
    dispatch(dialogs.actions.hide());
  };

  const onClose = () => {
    const triggerId = triggerIds.CLOSE_BUTTON;
    trackEvent(events.KEEP_MY_PLAN_TRIGGER_CLICKED, {
      triggerId,
      ...traits,
    });
    closeDialog({ triggerId });
  };

  const onKeepActiveClick = () => {
    const triggerId = triggerIds.KEEP_MY_PLAN_ACTIVE;
    trackEvent(events.KEEP_MY_PLAN_TRIGGER_CLICKED, {
      triggerId,
      ...traits,
    });
    closeDialog({ triggerId });
  };

  const onSubmit = async () => {
    const values = getValues();

    track(events.CANCEL_PLAN_DIALOG_SUBMITTED, {
      ...analyticsParams,
      [fieldNames.PRIMARY_REASON]: values[fieldNames.PRIMARY_REASON] || null,
      [fieldNames.SUB_REASONS]: values[fieldNames.SUB_REASONS].join() || null,
      [fieldNames.OTHER_PRIMARY_REASON]: values[fieldNames.OTHER_PRIMARY_REASON] || null,
      [fieldNames.OTHER_SUB_REASON]: values[fieldNames.OTHER_SUB_REASON] || null,
      [fieldNames.BETTER_ALTERNATIVE]: values[fieldNames.BETTER_ALTERNATIVE] || null,
    });

    try {
      await Promise.all(
        plansToCancel.map(async ({ id }) => {
          await dispatch(subscriptions.thunks.cancelSubscription(id));
        })
      );

      closeDialog({ isTrackAnalytics: false });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = () => {
    const values = getValues();

    if (activeStep === cancelPlanSteps.EXIT_SURVEY) {
      track(events.CANCEL_PLAN_EXIT_SURVEY_COMPLETED, {
        ...analyticsParams,
        [fieldNames.PRIMARY_REASON]: values[fieldNames.PRIMARY_REASON] || null,
        [fieldNames.OTHER_PRIMARY_REASON]: values[fieldNames.OTHER_PRIMARY_REASON] || null,
        [fieldNames.SUB_REASONS]: values[fieldNames.SUB_REASONS].join() || null,
        [fieldNames.OTHER_SUB_REASON]: values[fieldNames.OTHER_SUB_REASON] || null,
        [fieldNames.BETTER_ALTERNATIVE]: values[fieldNames.BETTER_ALTERNATIVE] || null,
      });
    }

    onNext();
  };

  const handleBack = () => {
    track(events.CANCEL_PLAN_BACK_TRIGGER_CLICKED, analyticsParams);

    onBack();
  };

  const onActivateDiscountClick = async () => {
    const response = await dispatch(products.thunks.createCancellationCoupon());

    track(events.CANCEL_PLAN_DISCOUNT_ACTIVATED, {
      type,
      term,
      category: triggerIds.HOSTING,
      triggerId: triggerIds.CANCEL_DISCOUNT_DIALOG,
      discountSize: `${response.discount}%`,
      discountActivated: hasEverHadDiscount,
      discountExpired: hasEverHadDiscount && !hasActivatedDiscount,
      onlineStoreIncluded,
    });

    closeDialog();
  };

  useEffect(() => {
    if (!discountPrice && !hasEverHadDiscount) {
      const queryTerm = term.toLowerCase();
      dispatch(products.thunks.fetchPrices(`package_cancellation_${queryTerm}`));
    }
  }, [discountPrice, term, hasEverHadDiscount, dispatch]);

  useEffect(() => {
    if (activeStep === cancelPlanSteps.DISCOUNT) {
      track(events.CANCEL_PLAN_DISCOUNT_DISPLAYED, analyticsParams);
    }

    track(events.CANCEL_PLAN_DIALOG_DISPLAYED, analyticsParams);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <React.Fragment>
      {activeStep === cancelPlanSteps.DISCOUNT ? (
        <CancelPlanDiscount
          captions={discountCaptions}
          basePrice={basePrice}
          discountPrice={discountPrice}
          isLoading={isDiscountActivating}
          onClose={onClose}
          onNext={onNext}
          onActivateDiscountClick={onActivateDiscountClick}
          isError={isDiscountActivatingError}
          clearError={clearDiscountActivationError}
        />
      ) : (
        <CancelPLanModal
          name={targetPackage.name}
          {...(!isFirstStep && { onBack: handleBack })}
          {...(!isLastStep && { next: { callback: handleNext, disabled: isDisabledNext } })}
          {...(isLastStep && { onSubmit })}
          isLoading={isCanceling}
          onlineStoreIncluded={onlineStoreIncluded}
          onClose={onClose}
          onKeepActiveClick={onKeepActiveClick}
        >
          {activeStep === cancelPlanSteps.EXIT_SURVEY && (
            <CancelPlanExitSurvey
              isClickToCancelComplianceEnabled={isClickToCancelComplianceEnabled}
              isHostingSubscription={isHostingSubscription}
              expiryDate={expiryDateFormatted}
              termsOfUseUrl={termsUrl}
              captions={captions[activeStep]}
              control={control}
              watchForm={watchForm}
              setFieldValue={setFieldValue}
            />
          )}
          {activeStep === cancelPlanSteps.CONFIRMATION && (
            <CancelPlanConfirmation
              captions={captions[activeStep]}
              plans={plansToCancel}
              details={cancelPlanDetails}
              additionalChanges={cancelPlanAdditionalChanges}
              isError={isError}
              clearError={clearError}
            />
          )}
        </CancelPLanModal>
      )}
    </React.Fragment>
  );
};

CancelPlanDialogContainer.propTypes = {
  subId: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  hasEverHadDiscount: PropTypes.bool.isRequired,
  hasActivatedDiscount: PropTypes.bool.isRequired,
};

export default CancelPlanDialogContainer;
