export default {
  PLAN_SELECTOR_DISPLAYED: 'PLAN_SELECTOR_DISPLAYED',
  PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED: 'PLAN_SELECTOR_PLAN_SELECT_BUTTON_CLICKED',
  PLAN_SELECTOR_SCROLLED_TO_BOTTOM: 'PLAN_SELECTOR_SCROLLED_TO_BOTTOM',
  PLAN_SELECTOR_TOOLTIP_DISPLAYED: 'PLAN_SELECTOR_TOOLTIP_DISPLAYED',
  PLAN_SELECTOR_TERM_TOGGLE_CLICKED: 'PLAN_SELECTOR_TERM_TOGGLE_CLICKED',
  PLAN_SELECTOR_BACK_BUTTON_CLICKED: 'PLAN_SELECTOR_BACK_BUTTON_CLICKED',
  DOMAIN_PURCHASE_DIALOG_DISPLAYED: 'DOMAIN_PURCHASE_DIALOG_DISPLAYED',
  DOMAIN_PURCHASE_SEARCH_CLICKED: 'DOMAIN_PURCHASE_SEARCH_CLICKED',
  DOMAIN_PURCHASE_SEARCH_PERFORMED: 'DOMAIN_PURCHASE_SEARCH_PERFORMED',
  DOMAIN_PURCHASE_DIALOG_CANCELED: 'DOMAIN_PURCHASE_DIALOG_CANCELED',
  DOMAIN_PURCHASE_CLICKED: 'DOMAIN_PURCHASE_CLICKED',
  DOMAIN_PURCHASE_AUTOCOMPLETE_DOMAIN_SELECTED: 'DOMAIN_PURCHASE_AUTOCOMPLETE_DOMAIN_SELECTED',
  DOMAIN_PURCHASE_NOT_NOW_CLICKED: 'DOMAIN_PURCHASE_NOT_NOW_CLICKED',
  DOMAIN_SEARCH_FAILED: 'DOMAIN_SEARCH_FAILED',
  DOMAIN_PURCHASE_SPECIAL_OFFER_SWITCHED: 'DOMAIN_PURCHASE_SPECIAL_OFFER_SWITCHED',
  APP_OPENED: 'APP_OPENED',
  DASHBOARD_DOMAIN_SEARCH_CLICKED: 'DASHBOARD_DOMAIN_SEARCH_CLICKED',
  BUY_A_DOMAIN_TRIGGER_CLICKED: 'BUY_A_DOMAIN_TRIGGER_CLICKED',
  UPGRADE_TRIGGER_CLICKED: 'UPGRADE_TRIGGER_CLICKED',
  SWITCH_TO_ANNUAL_TRIGGER_CLICKED: 'SWITCH_TO_ANNUAL_TRIGGER_CLICKED',
  ONLINE_STORE_TRIGGER_CLICKED: 'ONLINE_STORE_TRIGGER_CLICKED',
  SUBSCRIPTION_EXPIRED_DIALOG_DISPLAYED: 'SUBSCRIPTION_EXPIRED_DIALOG_DISPLAYED',
  SUBSCRIPTION_EXPIRED_DIALOG_CANCELLED: 'SUBSCRIPTION_EXPIRED_DIALOG_CANCELLED',
  RENEW_TRIGGER_CLICKED: 'RENEW_TRIGGER_CLICKED',
  SUBSCRIPTIONS_PAGE_DISPLAYED: 'SUBSCRIPTIONS_PAGE_DISPLAYED',
  REACTIVATE_TRIGGER_CLICKED: 'REACTIVATE_TRIGGER_CLICKED',
  SUBSCRIPTION_DETAILS_PAGE_DISPLAYED: 'SUBSCRIPTION_DETAILS_PAGE_DISPLAYED',
  BILLING_HISTORY_PAGE_DISPLAYED: 'BILLING_HISTORY_PAGE_DISPLAYED',
  PAYMENT_METHODS_PAGE_DISPLAYED: 'PAYMENT_METHODS_PAGE_DISPLAYED',
  PAYMENT_METHOD_DIALOG_DISPLAYED: 'PAYMENT_METHOD_DIALOG_DISPLAYED',
  PAYMENT_METHOD_DIALOG_SUBMITTED: 'PAYMENT_METHOD_DIALOG_SUBMITTED',
  PAYMENT_METHOD_DIALOG_CANCELLED: 'PAYMENT_METHOD_DIALOG_CANCELLED',
  REMOVE_PAYMENT_METHOD_DIALOG_DISPLAYED: 'REMOVE_PAYMENT_METHOD_DIALOG_DISPLAYED',
  REMOVE_PAYMENT_METHOD_DIALOG_SUBMITTED: 'REMOVE_PAYMENT_METHOD_DIALOG_SUBMITTED',
  REMOVE_PAYMENT_METHOD_DIALOG_CANCELLED: 'REMOVE_PAYMENT_METHOD_DIALOG_CANCELLED',
  SET_DEFAULT_PAYMENT_METHOD_DIALOG_DISPLAYED: 'SET_DEFAULT_PAYMENT_METHOD_DIALOG_DISPLAYED',
  SET_DEFAULT_PAYMENT_METHOD_DIALOG_SUBMITTED: 'SET_DEFAULT_PAYMENT_METHOD_DIALOG_SUBMITTED',
  SET_DEFAULT_PAYMENT_METHOD_DIALOG_CANCELED: 'SET_DEFAULT_PAYMENT_METHOD_DIALOG_CANCELED',
  ERROR_MESSAGE_DISPLAYED: 'ERROR_MESSAGE_DISPLAYED',
  HELP_DISPLAYED: 'HELP_DISPLAYED',
  CANCEL_PLAN_EXIT_SURVEY_COMPLETED: 'CANCEL_PLAN_EXIT_SURVEY_COMPLETED',
  CANCEL_SUBSCRIPTION_PAGE_DISPLAYED: 'CANCEL_SUBSCRIPTION_PAGE_DISPLAYED ',
  CANCEL_PLAN_TRIGGER_CLICKED: 'CANCEL_PLAN_TRIGGER_CLICKED',
  CANCEL_PLAN_DIALOG_DISPLAYED: 'CANCEL_PLAN_DIALOG_DISPLAYED',
  CANCEL_PLAN_BACK_TRIGGER_CLICKED: 'CANCEL_PLAN_BACK_TRIGGER_CLICKED',
  CANCEL_PLAN_DIALOG_CLOSED: 'CANCEL_PLAN_DIALOG_CLOSED',
  CANCEL_PLAN_DISCOUNT_DISPLAYED: 'CANCEL_PLAN_DISCOUNT_DISPLAYED',
  CANCEL_PLAN_DISCOUNT_ACTIVATED: 'CANCEL_PLAN_DISCOUNT_ACTIVATED',
  CANCEL_PLAN_DIALOG_SUBMITTED: 'CANCEL_PLAN_DIALOG_SUBMITTED',
  SUBSCRIPTION_DETAILS_PAGE_NOTIFICATION_CLOSED: 'SUBSCRIPTION_DETAILS_PAGE_NOTIFICATION_CLOSED',
  DIALOG_SUBMITTED: 'DIALOG_SUBMITTED',
  DIALOG_CANCELLED: 'DIALOG_CANCELLED',
  KEEP_MY_PLAN_TRIGGER_CLICKED: 'KEEP_MY_PLAN_TRIGGER_CLICKED',
  PROFILE_PAGE_DISPLAYED: 'PROFILE_PAGE_DISPLAYED',
  PROFILE_PAGE_PERSONAL_INFO_UPDATED: 'PROFILE_PAGE_PERSONAL_INFO_UPDATED',
  PROFILE_PAGE_ERROR_DISPLAYED: 'PROFILE_PAGE_ERROR_DISPLAYED',
  PROFILE_PAGE_EMAIL_UPDATED: 'PROFILE_PAGE_EMAIL_UPDATED',
  CHANGE_PASSWORD_DIALOG_DISPLAYED: 'CHANGE_PASSWORD_DIALOG_DISPLAYED',
  CHANGE_PASSWORD_DIALOG_SUBMITTED: 'CHANGE_PASSWORD_DIALOG_SUBMITTED',
  CHANGE_PASSWORD_DIALOG_CANCELLED: 'CHANGE_PASSWORD_DIALOG_CANCELLED',
  PROFILE_PAGE_LANGUAGE_CHANGED: 'PROFILE_PAGE_LANGUAGE_CHANGED',
  PROFILE_PAGE_EMAIL_SUBSCRIPTION_UPDATED: 'PROFILE_PAGE_EMAIL_SUBSCRIPTION_UPDATED',
  DELETE_ACCOUNT_DIALOG_DISPLAYED: 'DELETE_ACCOUNT_DIALOG_DISPLAYED',
  DELETE_ACCOUNT_DIALOG_SUBMITTED: 'DELETE_ACCOUNT_DIALOG_SUBMITTED',
  DELETE_ACCOUNT_DIALOG_CANCELLED: 'DELETE_ACCOUNT_DIALOG_CANCELLED',
  PROFILE_PAGE_HOW_TO_CANCEL_TUTORIAL_CLICKED: 'PROFILE_PAGE_HOW_TO_CANCEL_TUTORIAL_CLICKED',
  DASHBOARD_PAGE_DISPLAYED: 'DASHBOARD_PAGE_DISPLAYED',
  ADD_NEW_SITE_TRIGGER_CLICKED: 'ADD_NEW_SITE_TRIGGER_CLICKED',
  DOMAIN_SETTINGS_TRIGGER_CLICKED: 'DOMAIN_SETTINGS_TRIGGER_CLICKED',
  PAYMENT_METHOD_UPDATE_TRIGGER_CLICKED: 'PAYMENT_METHOD_UPDATE_TRIGGER_CLICKED',
  AUTO_RENEW_TURN_ON_TRIGGER_CLICKED: 'AUTO_RENEW_TURN_ON_TRIGGER_CLICKED',
};
