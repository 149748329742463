import React from 'react';
import PropTypes from 'prop-types';
import { designSystem, Modal } from '@yola/ws-ui';
import ErrorNotificationContainer from 'src/js/common/containers/error-notification-container';
import HotPriceIcon from './icons/hot-price-icon';
import CancelPlanDiscountItem from '../cancel-plan-discount-item';

const { Box, Stack, ActionButton, Heading, Paragraph, Mark, IconButton } = designSystem;
const WIDTH = 677;
const overlaySpacing = { y: 50 };

const CancelPlanDiscount = (props) => {
  const {
    captions,
    basePrice,
    discountPrice,
    isLoading,
    isError,
    onClose,
    onNext,
    onActivateDiscountClick,
    clearError,
  } = props;
  const {
    title: { gift, discount },
    description,
    buttonLabels: { activateDiscount, next },
    discountItem: { price, discountPrice: discountPriceCaption, term },
  } = captions;

  return (
    <Modal
      isBodyScrollDisabled
      isContainerScrollable
      width={WIDTH}
      overlaySpacing={overlaySpacing}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
      className="ws-cancel-plan-discount"
    >
      <Box
        paddingTop="spacing-xl"
        paddingRight="spacing-l"
        paddingBottom="spacing-xl"
        paddingLeft="spacing-l"
      >
        <div className="ws-cancel-plan-discount__close-button">
          <IconButton glyph="close" onClick={onClose} disabled={isLoading} transparent />
        </div>
        <Stack gap="spacing-xl">
          {isError && <ErrorNotificationContainer onClose={clearError} />}
          <div className="ws-cancel-plan-discount__icon">
            <HotPriceIcon />
          </div>
          <Stack gap="spacing-2-xs">
            <Heading type="heading-3" align="center">
              {gift} <Mark appearance="cta">{discount}</Mark>
            </Heading>
            <Paragraph size="large" align="center">
              {description}
            </Paragraph>
          </Stack>
          <div className="ws-cancel-plan-discount__items">
            <CancelPlanDiscountItem caption={price} price={basePrice} term={term} />
            <CancelPlanDiscountItem
              caption={discountPriceCaption}
              price={discountPrice}
              term={term}
              active
            />
          </div>
          <div className="ws-cancel-plan-discount__buttons">
            <ActionButton
              format="solid"
              appearance="cta"
              size="large"
              label={activateDiscount}
              isLoading={isLoading}
              onClick={onActivateDiscountClick}
            />
            <ActionButton format="text" label={next} disabled={isLoading} onClick={onNext} />
          </div>
        </Stack>
      </Box>
    </Modal>
  );
};

CancelPlanDiscount.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.shape({
      gift: PropTypes.string.isRequired,
      discount: PropTypes.string.isRequired,
    }),
    description: PropTypes.string.isRequired,
    discountItem: PropTypes.shape({
      price: PropTypes.string.isRequired,
      discountPrice: PropTypes.string.isRequired,
      term: PropTypes.string.isRequired,
    }).isRequired,
    buttonLabels: PropTypes.shape({
      activateDiscount: PropTypes.string.isRequired,
      next: PropTypes.string.isRequired,
    }),
  }).isRequired,
  basePrice: PropTypes.string.isRequired,
  discountPrice: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onActivateDiscountClick: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

CancelPlanDiscount.defaultProps = {
  isLoading: false,
  isError: false,
};

export default CancelPlanDiscount;
