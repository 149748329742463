import dialogTypes from '../constants/dialog-types';
import registerDialog from '../helpers/register-dialog';
import DeleteSiteDialogContainer from '../containers/delete-site-dialog-container';
import PendingDialog from '../components/pending-dialog';
import TermsOfUseDialogContainer from '../containers/terms-of-use-dialog-container';
import DomainSearchFailureDialog from '../../domain-selector/components/domain-search-failure-dialog';
import PaymentMethodFormDialog from '../../account-management/components/payment-method-form-dialog';
import SubscriptionExpiredDialogContainer from '../../account-management/containers/subscription-expired-dialog-container';
import DeletePaymentMethodDialog from '../../account-management/components/delete-payment-method-dialog';
import DeleteDefaultPaymentMethodDialog from '../../account-management/components/delete-default-payment-method-dialog';
import SetDefaultPaymentMethodDialog from '../../account-management/components/set-default-payment-method-dialog';
import CancelPlanDialogContainer from '../../account-management/containers/cancel-plan-dialog-container';
import DeleteAccountDialog from '../../profile/components/delete-account-dialog';
import ChangePasswordDialogContainer from '../../profile/containers/change-password-dialog-container';
import StripeLoadingErrorContainer from '../../stripe/containers/stripe-loading-error-container';
import ResetSiteDialogContainer from '../containers/reset-site-dialog-container';
import ResetSiteUnpublishConfirmDialogContainer from '../containers/reset-site-unpublish-confirm-dialog-container';
import UnpublishSiteDialogContainer from '../containers/unpublish-site-dialog-container';
import ExportInformationDialogContainer from '../containers/export-information-dialog-container';
import LegacySiteErrorDialogContainer from '../containers/legacy-site-error-dialog-container';

function registerDialogs() {
  registerDialog(dialogTypes.DELETE_SITE, DeleteSiteDialogContainer);
  registerDialog(dialogTypes.PENDING, PendingDialog);
  registerDialog(dialogTypes.DOMAIN_SEARCH_FAILURE, DomainSearchFailureDialog);
  registerDialog(dialogTypes.PAYMENT_METHOD_FORM, PaymentMethodFormDialog);
  registerDialog(dialogTypes.SUBSCRIPTION_EXPIRED, SubscriptionExpiredDialogContainer);
  registerDialog(dialogTypes.DELETE_PAYMENT_METHOD, DeletePaymentMethodDialog);
  registerDialog(dialogTypes.DELETE_DEFAULT_PAYMENT_METHOD, DeleteDefaultPaymentMethodDialog);
  registerDialog(dialogTypes.SET_DEFAULT_PAYMENT_METHOD, SetDefaultPaymentMethodDialog);
  registerDialog(dialogTypes.CANCEL_PLAN, CancelPlanDialogContainer);
  registerDialog(dialogTypes.DELETE_ACCOUNT, DeleteAccountDialog);
  registerDialog(dialogTypes.CHANGE_PASSWORD, ChangePasswordDialogContainer);
  registerDialog(dialogTypes.TERMS_OF_USE_DIALOG, TermsOfUseDialogContainer);
  registerDialog(dialogTypes.STRIPE_LOADING_ERROR, StripeLoadingErrorContainer);
  registerDialog(dialogTypes.RESET_SITE, ResetSiteDialogContainer);
  registerDialog(
    dialogTypes.RESET_SITE_UNPUBLISH_CONFIRM,
    ResetSiteUnpublishConfirmDialogContainer
  );
  registerDialog(dialogTypes.UNPUBLISH_SITE, UnpublishSiteDialogContainer);
  registerDialog(dialogTypes.EXPORT_INFORMATION_DIALOG, ExportInformationDialogContainer);
  registerDialog(dialogTypes.LEGACY_SITE_ERROR_DIALOG, LegacySiteErrorDialogContainer);
}

export default registerDialogs;
