export const fieldNames = {
  PRIMARY_REASON: 'primaryReason',
  SUB_REASONS: 'subReasons',
  OTHER_PRIMARY_REASON: 'otherPrimaryReason',
  OTHER_SUB_REASON: 'otherSubReason',
  BETTER_ALTERNATIVE: 'betterAlternative',
};
export const SUB_FIELD_OTHER_VALUE = 'Other';
export const primaryReasonValues = {
  NO_NEED_WEBSITE: 'No longer need the website',
  TOO_EXPENSIVE: 'Too expensive',
  CANT_BUILD_WEBSITE: 'Can’t build a site by myself',
  ISSUE_WITH_BUILDER: 'Issues with Sitebuilder',
  NO_RESULTS: 'Not seeing the results I wanted',
  MISSING_FEATURES: 'Missing feature(s)',
  OTHER: 'Other',
};
export const ecommercePrimaryReasonValues = {
  NO_NEED_ONLINE_STORE: 'No longer need an Online store',
  TOO_EXPENSIVE: 'Too expensive',
  DIFFICULTY_MANAGING: 'Difficulty managing the store',
  ISSUES_WITH_ONLINE_STORE: 'Issues with Online Store',
  LIMITED_DESIGN: 'Limited design options',
  MISSING_FEATURES: 'Missing feature(s)',
  BETTER_ALTERNATIVE: 'Better alternative',
  OTHER: 'Other',
};
export const noNeedOnlineStoreValues = {
  HARD_TO_USE: 'Online Store is hard to use',
  NOT_ENOUGH_CUSTOMIZATION: 'Online Store doesn’t provide enough customization options',
  SUPPORT_IS_NOT_HELPFUL: 'Customer support isn’t helpful',
  HELPER_CENTER_IS_NOT_HELPFUL: 'Help Center isn’t helpful',
  PLATFORM_IS_NOT_STABLE: 'The Store platform is not stable',
  OTHER: 'Other',
};
