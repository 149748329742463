import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { constants, verifiers } from '@yola/subscription-manager-js';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import segment from '../../modules/analytics/segment';
import PaywallContainer from '../../modules/paywall/containers/paywall-container';
import useSupportedSearchParams from '../hooks/use-supported-search-params';
import routesMap from '../helpers/routes-map';
import user from '../../modules/user';
import utils from '../../modules/webapp-plugins/utils';

const {
  constants: { triggerIds },
} = segment;
const { DOMAIN_SUBSCRIPTION } = constants.SubscriptionType;

function PaywallRoute() {
  const searchParams = useSupportedSearchParams();
  const isB2C = useSelector(user.selectors.getIsB2C);
  const history = useHistory();

  if (!searchParams) return null;

  const {
    initiator,
    products = [],
    term,
    domain,
    premiumFeature,
    triggerId,
    back,
    periodToggle,
    domainProposal,
    paymentBackUrl,
  } = searchParams;
  const hasProducts = Boolean(products.length);
  const isPaidHostingProduct = hasProducts && verifiers.hasPaidHostingPackageType(products);

  if (isPaidHostingProduct) {
    const domainProduct = domain ? [DOMAIN_SUBSCRIPTION] : [];

    redirectToUrl(
      utils.generatePaymentUrl(
        {
          products: [...products, ...domainProduct],
          ...(domain && { domain_name: domain }),
          ...(initiator && { initiator }),
          ...(term && { term }),
          ...(paymentBackUrl && { backUrl: paymentBackUrl }),
        },
        isB2C
      )
    );

    return null;
  }

  const propsFromSearchParams = {
    ...(initiator && { initiator }),
    ...(premiumFeature && { premiumFeature }),
    ...(domain && { domain }),
    ...(hasProducts && { extraProducts: products }),
    ...(term && { term }),
    ...(triggerId && { triggerId }),
    ...(back && { backUrl: back }),
    ...(paymentBackUrl && { paymentBackUrl }),
    periodToggle: periodToggle !== false,
    domainProposal: domainProposal !== false,
  };

  const showDomainSelector = (selectedPlan, selectedTerm) => {
    const productsParam = [...products, selectedPlan].join();

    history.push(
      routesMap.domainSelector.url({
        query: {
          products: productsParam,
          term: selectedTerm,
          triggerId: triggerIds.PAYWALL,
          ...(paymentBackUrl && { paymentBackUrl }),
        },
      })
    );
  };

  return <PaywallContainer showDomainSelector={showDomainSelector} {...propsFromSearchParams} />;
}

export default PaywallRoute;
