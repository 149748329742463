import i18next from 'i18next';

const getValidationRules = (required) => ({
  subFieldOther: {
    minLength: {
      value: 10,
      message: i18next.t('Requires at least {length} characters', {
        length: 10,
      }),
    },
    required: required && i18next.t('This field is required'),
  },
  fieldOther: {
    minLength: {
      value: 20,
      message: i18next.t('The message should include more than {length} symbols', {
        length: 20,
      }),
    },
    required: required && i18next.t('This field is required'),
  },
});

export default getValidationRules;
